import "./footer.scss";
import { getLink } from "src/utility/functions";
import {
  aboutLinks,
  projectLinks,
  downloadLinks,
  blogLinks,
  contactLinks,
  jobLinks,
} from "src/data/project-data";

function SiteFooter() {
  return (
    <footer className="border">
      <div className="outer-flex footer-content">
        <div className="inner-flex columns">
          <div className="column">
            <ul>
              <li>{getLink({ url: "/", innerElement: "Home" })}</li>
              <li>
                {getLink({
                  url: blogLinks.url,
                  icon: blogLinks.icon,
                  innerElement: blogLinks.name,
                })}
              </li>
              <li>
                {getLink({
                  url: downloadLinks.url,
                  icon: downloadLinks.icon,
                  innerElement: downloadLinks.name,
                })}
              </li>
              {/* <li>
                {getLink({
                  url: jobLinks.url,
                  icon: jobLinks.icon,
                  innerElement: jobLinks.name,
                })}
              </li> */}
              <li>
                {getLink({
                  url: aboutLinks.url,
                  icon: aboutLinks.icon,
                  innerElement: aboutLinks.name,
                })}
              </li>
            </ul>
          </div>
          <div className="column">
            <ul>
              <li>{projectLinks.name}</li>
              {projectLinks.subMenu?.map((subNavLink, subIndex) => (
                <li key={subIndex}>
                  {getLink({
                    url: subNavLink.url,
                    icon: subNavLink.icon,
                    innerElement: subNavLink.name,
                  })}
                </li>
              ))}
            </ul>
          </div>
          <div className="column">
            <ul>
              <li>{contactLinks.name}</li>
              {contactLinks.subMenu?.map((subNavLink, subIndex) => (
                <li key={subIndex}>
                  {getLink({
                    url: subNavLink.url,
                    icon: subNavLink.icon,
                    innerElement: subNavLink.name,
                  })}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default SiteFooter;
