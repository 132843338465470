import { getLink } from "src/utility/functions";

import "./site-button.scss";

function SiteButton({
  label,
  url,
  title,
}: {
  label: string;
  url: string | undefined;
  title?: string | undefined;
}) {
  // return a new link, wrapping a button
  return getLink({
    url: url,
    innerElement: <button className="site-button">{label}</button>,
    title: title,
  });
}

export default SiteButton;
