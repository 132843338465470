import { ProjectData } from "../data/project-data";
import SiteButton from "src/components/site-button";
import { onImageLoadUtility } from "src/utility/functions";

import "./project.scss";

function Project({ projectData }: { projectData: ProjectData }) {
  return (
    <section className="project">
      <div className="project-image">
        <img
          src={projectData.logos.logo}
          alt="Project"
          className="fade-in"
          onLoad={onImageLoadUtility}
        />
      </div>
      <div className="project-content">
        <h3>{projectData.name}</h3>
        <p>{projectData.descs.descShort}</p>
        <div className="project-links">
          {projectData.urls.map((url) => {
            return (
              <SiteButton
                label={url.name}
                url={url.link ? url.link : ""}
                key={url.name}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Project;
