import Project from "src/components/project";
import { ProjectDataGroup, ProjectData } from "src/data/project-data";

import "./page.scss";
import "./projects.scss";
import { useEffect } from "react";
import { scrollTopFunction } from "src/utility/functions";
import { useNavigate } from "react-router-dom";

interface IProjectsProps {
  projectDataGroup: ProjectDataGroup;
}

function Projects({ projectDataGroup }: IProjectsProps) {
  const navigate = useNavigate();
  useEffect(() => {
    scrollTopFunction();
  }, [navigate]);

  return (
    <>
      <section className="outer-flex projects-intro">
        <div className="inner-flex inner">
          <h2>{projectDataGroup.heading}</h2>
          {projectDataGroup.desc && <p>{projectDataGroup.desc}</p>}
        </div>
      </section>
      <section className="outer-flex projects section-content">
        <div className="inner-flex inner">
          {projectDataGroup.projects.map((project: ProjectData) => {
            return <Project projectData={project} key={project.name} />;
          })}
        </div>
      </section>
    </>
  );
}

export default Projects;
