import { Routes, Route, Navigate } from "react-router-dom";

import Main from "src/pages/main";
import About from "src/pages/about";
import Downloads from "src/pages/downloads";
import Projects from "src/pages/projects";
import {
  openSourceProjectData,
  customerProjectData,
  industryProjectData,
  jobsDataGroup,
} from "src/data/project-data";
import Jobs from "src/pages/jobs";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/about" element={<About />} />
      <Route path="/jobs" element={<Jobs jobsDataGroup={jobsDataGroup} />} />
      <Route path="/downloads" element={<Downloads />} />
      <Route
        path="/projects-open-source"
        element={<Projects projectDataGroup={openSourceProjectData} />}
      />
      <Route
        path="/projects-customer"
        element={<Projects projectDataGroup={customerProjectData} />}
      />
      <Route
        path="/projects-industry"
        element={<Projects projectDataGroup={industryProjectData} />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Router;
